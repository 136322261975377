import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';


// function Square(props) {
//     return (
//         <button 
//         className="square" 
//         onClick={props.onClick}>
//           {props.value}
//         </button>
//       );
// }
// // Just insert the inside of render() and replace this.props with just props
// // also change onClick={() => props.onClick()} take away the () on both sides
// // class Square extends React.Component {
// //     render() {
// //       return (
// //         <button 
// //         className="square" 
// //         onClick={() => this.props.onClick()}>
// //           {this.props.value}
// //         </button>
// //       );
// //     }
// //   }
  
//   class Board extends React.Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             squares: Array(9).fill(null),
//             XisNext: true,
//         }
//     }
//     handleClick(i) {
//         const squares = this.state.squares.slice();
//         if (calculateWinner(this.state.squares || squares[i])) {
//             return;
//         }
//         squares[i] = this.state.XisNext ? "X" : "O";
//         this.setState({
//             squares: squares, 
//             XisNext: !this.state.XisNext
//         });
//     }
//     renderSquare(i) {
//       return (
//         <Square 
//             value={this.state.squares[i]}
//             onClick={() => this.handleClick(i)} />
//       )
//     }
  
//     render() {
//       let status;
//       const winner = calculateWinner(this.state.squares);
//       if (winner) {
//         status = 'Winner ' + winner;
//       } else {
//         status = `Next player: ${this.state.XisNext ? "X" : "O"}`;
//       }
  
//       return (
//         <div>
//           <div className="status">{status}</div>
//           <div className="board-row">
//             {this.renderSquare(0)}
//             {this.renderSquare(1)}
//             {this.renderSquare(2)}
//           </div>
//           <div className="board-row">
//             {this.renderSquare(3)}
//             {this.renderSquare(4)}
//             {this.renderSquare(5)}
//           </div>
//           <div className="board-row">
//             {this.renderSquare(6)}
//             {this.renderSquare(7)}
//             {this.renderSquare(8)}
//           </div>
//         </div>
//       );
//     }
//   }
  
//   class Game extends React.Component {
//     render() {
//       return (
//         <div className="game">
//           <div className="game-board">
//             <Board />
//           </div>
//           <div className="game-info">
//             <div>{/* status */}</div>
//             <ol>{/* TODO */}</ol>
//           </div>
//         </div>
//       );
//     }
//   }

class Baller extends React.Component {
  render() {
    return (
      <div>
        <h1 id="main">Am I a baller?</h1>
        <button onClick={doEvent}>Find Out</button>
      </div>
    )
  }
}

function doEvent() {
  const div = document.getElementById("main")
  div.innerHTML = "YES! Samiha, you are a baller!"
}
  
  // start: helper function
  function calculateWinner(squares) {
    const lines = [
      [0, 1, 2],
      [3, 4, 5],
      [6, 7, 8],
      [0, 3, 6],
      [1, 4, 7],
      [2, 5, 8],
      [0, 4, 8],
      [2, 4, 6],
    ];
    for (let i = 0; i < lines.length; i++) {
      const [a, b, c] = lines[i];
      if (squares[a] && squares[a] === squares[b] && squares[a] === squares[c]) {
        return squares[a];
      }
    }
    return null;
  }
  // end: helper function 
  // ========================================
  
  const root = ReactDOM.createRoot(document.getElementById("root"));
  // root.render(<Game />);
  root.render(<Baller />);